import React from 'react';
import { graphql } from 'gatsby';
import { TopLevelPage } from '../components/TopLevelPage';
import { SEO } from '../components/SEO';

export default function Narrative({ data }) {
  const { url } = data.settings.edges[0].node.background_video.asset;
  const { title, vimeo_url } = data.settings.edges[0].node;
  const { clients } = data.settings.edges[0].node;
  return (
    <>
      <SEO title="Narrative: Experience our works of fiction." />
      <TopLevelPage
        backgroundVideoUrl={url}
        fromPage="narrative"
        clients={clients}
        galleryItems={data.allSanityNarrative}
        vimeo_url={vimeo_url}
        title={title}
      />
    </>
  );
}

export const query = graphql`
  {
    settings: allSanityNarrativeSettings {
      edges {
        node {
          id
          background_video {
            asset {
              url
            }
          }
          title
          vimeo_url
          clients {
            Logo {
              asset {
                url
              }
            }
          }
        }
      }
    }

    allSanityNarrative(sort: { fields: order }) {
      edges {
        node {
          id
          title
          _rawDescription(resolveReferences: { maxDepth: 10 })
          gallery_image {
            _key
            asset {
              fluid(maxWidth: 200, maxHeight: 113) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
